<template>
    <div>
        <v-row v-if="client" no-gutters>
            <v-col cols="2" class="d-flex align-center">
                <span>Contacts</span>
            </v-col>
            <v-col class="d-flex align-center flex-wrap">
                <Contact
                    v-for="contact in filterContacts"
                    :key="contact.id"
                    :contact="contact"
                    :contactRoles="client.companyRoles"
                    :projects="client.projects"
                    @removeContact="removeContact"
                    @replaceContact="replaceContact"
                />
                <div>
                    <v-btn
                        depressed
                        fab
                        x-small
                        color="primary"
                        @click="openCreateDialogNew"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-dialog
                        :retain-focus="false"
                        v-model="createDialogNew"
                        persistent
                        max-width="600px"
                    >
                        <ContactForm
                            title="New Contact"
                            :create-form="true"
                            @closeDialog="closeCreateDialogNew"
                            @addContact="addContact"
                            :contactRoles="client.companyRoles"
                            :clientId="client.id"
                        />
                    </v-dialog>
                </div>
            </v-col>
        </v-row>

        <v-row no-gutters v-if="client" class="pb-2 pt-2">
            <v-col cols="2" class="d-flex align-center">
                <span>City</span>
            </v-col>
            <v-col class="d-flex align-center">
                <span>{{ client.city }}</span>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="client" class="pb-2">
            <v-col cols="2" class="d-flex align-center">
                <span>Currency</span>
            </v-col>
            <v-col class="d-flex align-center">
                <span
                    >{{ client.currency
                    }}{{
                        client.currency2 ? ` - ${client.currency2}` : ''
                    }}</span
                >
            </v-col>
        </v-row>
        <v-row v-if="client" no-gutters class="pb-2">
            <v-col cols="2" class="d-flex align-center">
                <span>Language</span>
            </v-col>
            <v-col class="d-flex align-center">
                <v-avatar size="24" class="me-2 my-1">
                    <v-img
                        :src="
                            client.language.toLowerCase() === 'english'
                                ? require('@/assets/usa_flag.png')
                                : require('@/assets/col_flag.png')
                        "
                    />
                </v-avatar>
            </v-col>
        </v-row>
        <v-divider class="my-4" />
    </div>
</template>

<script>
import ContactForm from '@/components/Clients/ContactForm'
import Contact from '@/components/Clients/Contact'
import codesCurrency from '@/assets/codes-all_json.json'
import { mapActions, mapMutations } from 'vuex'
import API from '@/services/api'
import { storage } from '@/services/firebase'

export default {
    name: 'ClientGeneralInfo',
    components: {
        ContactForm,
        Contact,
    },
    props: {
        id: String,
        client: Object,
    },
    data() {
        return {
            createDialogNew: false,
            itemsCurrency: null,
            loading: false,
            error: false,
            errorMsg: null,
            companyId: JSON.parse(localStorage.getItem('company')),
            folderPictures: 'clients_pictures',
            val: false,
        }
    },
    watch: {
        client: async function() {
            await this.getContactsByClient()
            this.val = false
            this.val = true
        },
    },
    computed: {
        filterContacts: function() {
            return this.val ? this.client.contacts : []
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openCreateDialogNew: function() {
            this.createDialogNew = true
        },
        ...mapActions(['replaceClientAction']),

        selectCurrency: function(event) {
            if (this.client.exchange) {
                this.client.exchange.currency = event
            } else {
                this.client.exchange = { currency: event }
            }
        },
        selectMoney: function(event) {
            if (this.client.exchange) {
                this.client.exchange.money = event
            } else {
                this.client.exchange = { money: event }
            }
        },
        closeCreateDialogNew: function() {
            this.createDialogNew = false
        },
        closeDialogExchange: function() {
            this.dialogExchange = false
        },
        addContact: function(contact) {
            this.client.contacts.push(contact)
        },
        removeContact: function(id) {
            const index = this.client.contacts.findIndex(u => u.id === id)
            this.client.contacts.splice(index, 1)
            this.val = false
            this.val = true
        },
        replaceContact: function(id, contact) {
            const index = this.client.contacts.findIndex(u => u.id === id)
            this.client.contacts.splice(index, 1, contact)
            this.val = false
            this.val = true
        },
        saveExchange: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.client
                const {
                    data: { client },
                } = await API.updateClient(id, {
                    exchange: this.client.exchange,
                })
                client.projects = this.client.projects
                client.contacts = this.client.contacts
                client.companyRoles = this.client.companyRoles
                client.profilePicURL = this.client.profilePicURL
                this.replaceClientAction({ id, client })
                this.closeDialogExchange()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getContactsByClient() {
            try {
                this.loading = true
                const {
                    data: { contacts },
                } = await API.getContactsByClient({ clientId: this.client.id })
                for (const contact of contacts) {
                    if (contact.picture) {
                        const path = `${this.companyId}/${this.folderPictures}`
                        const storageRef = storage().ref(path)
                        const profilePicRef = storageRef.child(contact.picture)
                        await profilePicRef
                            .getDownloadURL()
                            .then(url => {
                                contact.profilePicURL = url
                            })
                            .catch(error => {
                                ;(contact.profilePicURL = ''), error && true
                            })
                        contact.companyRoles = this.client.companyRoles
                    }
                }
                this.client.contacts = contacts
                this.val = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    async created() {
        await this.getContactsByClient()
        this.itemsCurrency = codesCurrency.map(x => x.AlphabeticCode)
    },
}
</script>

<style scoped>
.text-singleline {
    line-height: 0.75rem;
}
</style>
